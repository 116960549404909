@media screen and (max-width: 768px) {
  .contact {
    max-width: 90vw;
  }
}

@media screen and (min-width: 768px) {
  .contact {
    min-width: 490px;
    top: 25%;
    left: calc(50vw - 245px);
    position: absolute;
  }

  .contact button:hover {
    background-color: var(--color-accent);
    color: #fff;
  }
}

.tick-animation {
  display: flex;
  justify-content: center;
  align-items: center;

  .circle {
    fill: transparent;
    stroke: green;
    stroke-width: 5;
    stroke-dasharray: 452.6;
    stroke-dashoffset: -452.6;
    animation: drawCircle 0.5s forwards;
  }

  .tick {
    fill: transparent;
    stroke: green;
    stroke-width: 5;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards;
  }
}

@keyframes drawCircle {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.contact {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: var(--text-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.contact h4 {
  color: var(--color-accent);
  font-size: 35px;
  text-align: center;
  font-weight: bold;
}

.form-group {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 0 auto;
}

.contact label {
  color: var(--color-text);
  text-align: left;
  font-size: 18px;
  margin-bottom: 5px;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
}

.contact textarea {
  resize: vertical;
}

.contact textarea:focus,
.contact input:focus {
  outline: none;
  border: 1px solid var(--color-accent);
}

.contact button {
  width: 100%;
  padding: 12px;
  background-color: var(--color-secondary);
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
