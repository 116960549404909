@media screen and (max-width: 768px) {
  .services-body {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .services-body {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 991px) {
  .services-body {
    grid-template-columns: repeat(4, 1fr);
  }

  .services-body-item:hover {
    transform: translateY(-5px);
  }
}

.services-body {
  max-width: fit-content;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.services-body-item {
  min-height: 30vh;
  max-width: 300px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.services {
  margin: 0 auto;
  padding: 20px 20px 50px;
  background-color: #f8f8f8;
  color: var(--color-text);
}

.services-header {
  text-align: center;
  margin-bottom: 20px;
}

.services-header h2 {
  color: var(--color-accent);
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 5px;
}

.services-header p {
  font-size: 18px;
  line-height: 1.6;
}

.services-body-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.services-body-item-icon img {
  width: 100%;
  height: 18vh;
  object-fit: cover;
  object-position: center;
}

.services-body-item-text h3 {
  font-size: 19px;
  font-weight: bold;
  color: var(--color-accent);
  line-height: 1;
  max-lines: 1;
  text-overflow: ellipsis;
}

.services-body-item-text p {
  font-size: 16px;
  line-height: 1.6;
  overflow: hidden;
  height: 7.2em;
}

.services-body-item .button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-accent);
  color: white;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.services-body-item .button:hover {
  background-color: darken(var(--color-accent), 10%);
}
