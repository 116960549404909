@media screen and (max-width: 768px) {
  .desktop-menu,
  .navbar-logo {
    display: none;
  }

  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: var(--color-primary);
    color: #fff;
    transition: right 0.5s;
  }

  .menu-toggle {
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
  }

  .bar {
    width: 30px;
    height: 3px;
    margin: 4px;
    opacity: 1;
  }

  .close-btn {
    all: unset;
    position: absolute;
    font-size: xx-large;
    top: 0;
    right: 20px;
    padding: 10px;
    cursor: pointer;
  }

  .mobile-menu.open {
    right: 0;
  }

  .menu-items {
    list-style: none;
    padding: 0;
    margin: 50px 0;
  }

  .menu-items li button {
    all: unset;
  }

  .menu-items li button.active {
    color: var(--color-accent);
  }

  .menu-items li {
    padding: 10px 20px;
    opacity: 0;
    transform: translateX(50px);
  }

  .menu-items li.slide-in {
    animation: slideIn 0.5s forwards;
  }

  .menu-items li:nth-child(1) {
    animation-delay: 0.2s;
  }

  .menu-items li:nth-child(2) {
    animation-delay: 0.4s;
  }

  .menu-items li:nth-child(3) {
    animation-delay: 0.6s;
  }

  .menu-items li:nth-child(4) {
    animation-delay: 0.8s;
  }

  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

@media screen and (min-width: 768px) {
  .mobile-menu-container {
    display: none;
  }

  .navbar {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    z-index: 100;
    position: fixed;
    width: 100%;
    padding-right: 5%;
  }

  .navbar.active {
    top: -30px;
    transform: translateY(30px);
    background-color: var(--color-primary);
    transition: transform 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  }

  .navbar-logo {
    height: 70px;
    width: 200px;
    margin-left: 10%;
    background-image: url("../assets/logo.png");
    background-size: 99%;
    background-color: transparent;
    background-position: center;
    object-fit: cover;
    margin-top: -3px;
    border: none;
  }

  .navbar-links ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .navbar-links ul li {
    margin-right: 20px;
  }

  .navbar-links button {
    background-color: transparent;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 5px;
  }

  .navbar-links ul li button.active {
    border-color: var(--color-accent);
    color: var(--color-accent);
  }

  .navbar-links ul li button:hover {
    color: var(--color-accent);
  }
}

#to-top {
  all: unset;
  position: fixed;
  bottom: -100%;
  right: 20px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: 2;
}

#to-top svg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--color-accent);
  fill: #fff;
}

#to-top.active {
  transition: bottom 0.5s cubic-bezier(0.61, 1, 0.88, 1);
  bottom: 20px;
}
