@media screen and (max-width: 768px) {
  #footer-svg {
    padding-bottom: 15%;
  }

  footer h3 {
    text-align: center;
    color: var(--color-accent);
    width: 100%;
    margin-top: 10px;
  }

  .footer-link-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .footer-links {
    padding: 0 4.5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: repeat(1, 1fr);
  }

  footer button:active {
    color: var(--color-accent);
  }
}

@media screen and (min-width: 768px) {
  .footer-links {
    padding: 0 15%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-link-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer button:hover {
    color: var(--color-accent);
  }
}

footer {
  background-color: var(--color-primary);
  color: #fff;
  max-height: 40vh;
  text-align: center;
  position: relative;
}

footer h3 {
  color: var(--color-accent);
}

footer a,
footer svg {
  color: var(--color-accent);
  text-decoration: none;
}

footer button {
  border: none;
  background-color: transparent;
  color: #fff;
}

.footer-link-group ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-link-group li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer-socials svg {
  font-size: 25px;
}

.footer-socials svg:hover {
  color: #fff;
}

.footer-logo {
  display: flex;
  justify-content: center;
  gap: 10px;
}

#footer-svg {
  padding-bottom: 15%;
}
