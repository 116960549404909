@media screen and (max-width: 768px) {
  .about {
    border: 1px solid green;
    margin-top: 10%;
    padding: 4%;
  }

  .about h3 {
    color: var(--color-accent);
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .about p {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .about {
    padding: 2%;
  }

  .about h3 {
    color: var(--color-accent);
    font-size: 35px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .about p {
    font-size: 20px;
    line-height: 1.6;
  }

  .about ul {
    list-style-type: disc;
    margin-left: 30px;
    margin-bottom: 20px;
  }
}

.about-container {
  min-height: 100vh;
  padding: 10% 6%;
  background-size: cover;
  background-position: 0 0;
  background-image: url("../assets/abt-bg.webp");
}

.about {
  min-height: fit-content;
  max-width: fit-content;
  border-radius: 10px;
  opacity: 0.9;
  background-color: #f8f8f8;
}

.about ul {
  max-width: 500px;
  white-space: nowrap;
  height: auto;
}

.about li {
  font-size: 16px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.about li::before {
  content: "✓ ";
  color: var(--color-accent);
  font-size: 18px;
  margin-right: 5px;
}
