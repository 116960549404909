@media screen and (max-width: 768px) {
  #particles-js,
  .homepage h1 br {
    display: none;
  }

  .homepage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10% 6%;
    min-height: 100vh;
    background-color: var(--color-primary);
    color: #fff;
    background-image: url("../assets/mob-bg.jpg");
    background-size: cover;
  }

  .homepage h1 {
    color: var(--color-accent);
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  .homepage p {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  #home button:active {
    background-color: var(--color-accent);
  }
}

@media screen and (min-width: 768px) {
  .homepage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10% 6%;
    height: 100vh;
    background-color: var(--color-primary);
    color: #fff;
    background-image: url("../assets/home-bg.webp");
    background-size: cover;
  }

  .homepage h1 {
    color: var(--color-accent);
    font-size: 4rem;
    font-weight: 800;
  }

  .homepage p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  #particles-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #home button:hover {
    background-color: var(--color-accent);
  }
}

#home button {
  font-size: 20px;
  background-color: var(--color-secondary);
  color: #fff;
  padding: 10px 20px;
  border: none;
  z-index: 2;
  pointer-events: all;
  cursor: pointer;
}
