@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Nunito:wght@400;600;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --color-primary: #0A192f;
  --color-secondary: #00CC99;
  --color-accent: #ff9900;
  --color-text: #1c1c1c;
}

body {
  font-family: 'Lato', sans-serif;
  background-color: var(--color-primary);
  color: var(--color-text);
}

h1 {
  font-family: 'Nunito', sans-serif;
}
